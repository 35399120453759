// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import TileGallery from '../components/tileGallery'
// Step 2: Define your component
const CatsPage = () => {
  return (
    <Layout pageTitle="Cats">
      <TileGallery galleryId="Cats"/>
    </Layout>
  )
}

// Step 3: Export your component
export default CatsPage

export const Head = () => <title>Cats</title>

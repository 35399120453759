import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { galleryImage, gallery} from './tileGallery.module.css'

const TileGallery = ({ galleryId }) => {
  const data = useStaticQuery(graphql`{
        allFile(filter: {dir: {regex: "/[^]*images[^]*/"}}) {
          edges {
            node {
              id
              name
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
              dir
            }
          }
        }
      }`)
      console.log(data)
    const regSpression = RegExp("[^]*"+galleryId+"[^]*")
    return (
        <div className={gallery}>
            {data.allFile.edges.map(el => {
              if (regSpression.test(el.node.dir)){
                const anImage = getImage(el.node)
                return (<GatsbyImage class={galleryImage} alt={el.node.name} image={anImage} key={el.node.id} />)
              } else {
                return 
              }
            })}
        </div>
    )
}

export default TileGallery